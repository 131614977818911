.content {
  background-color: black;
  font-family: var(--body-font);
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white
}

.form {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.button {
  font-family: var(--body-font);
  background-color: var(--primary-color);
  border: none;
  border-radius: 6px;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
}

input, textarea {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}